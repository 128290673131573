<template>
  <div v-loading="loading" element-loading-text="请求中">
    <div class="device">
      <span style="line-height: 30px;">设备名称：</span>
      <span style="line-height: 30px;width: 200px;display:inline-block; margin-right: 20px;">{{device.title?device.title:device.uid}}</span>
    </div>
    <!-- <div class="c-container-box">
      <el-table :data="fileList">
        <el-table-column prop="title" label="文档名称" width="400"></el-table-column>
        <el-table-column prop="type" label="文档格式"></el-table-column>
        <el-table-column label="操作" fixed="right" width="100">
          <template slot-scope="scope">
            <el-button type="primary" icon="el-icon-view" circle size="mini" @click="toView(scope.row)">
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div> -->
  </div>

</template>

<script>
export default {
  data() {
    return {
      device: {
        uid: '',
        model: '',
        title: ''
      },
      loading: false,
      root_path: "/doc/public",//根目录
    }
  },
  watch: {

  },
  mounted() {
    this.ws.addCallback('folderContentAck', (res) => {
      this.on_folderContentAck(res)
    })
    console.log(111, this.root_path);

    this.folderContentFun(this.root_path)
  },
  methods: {
    // 获取文件资源
    folderContentFun(folder) {
      let json = {
        "cmd": "folderContent",
        "folder": folder
      }

      this.ws.send(json);
    },

    on_folderContentAck(res) {
      console.log(111, res);
    }

  }

}
</script>

<style lang="scss" scoped>
</style>